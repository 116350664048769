import React, { useContext } from 'react';
import styled from "styled-components";
import { FormattedMessage } from 'react-intl';
import messages from '../../../../src/messages';
import { LangContext } from '../../../../src/ConnectedIntlProvider';

const StyledFooter = styled.div`
   //      background:white;
         position:relative;
         bottom:0px;
         width:100%;
  height: fit-content;
  margin: 0;

  text-align: center;
  font-size: 13px;
  border-top: 1px solid #e4e4e4;
  padding-top: 15px;
 
    margin: 0;
    background: transparent;
    padding-bottom: 15px;
    margin-top:25px;
    
    span{
        
    }
    
    li{
             float:right;
              margin-right:35px;
             
             @media(max-width:350px){
                 width: 100%;
                 margin-top: 15px;
                 margin-right: 0;
             }
             
         }
  &.editorFooter{
  
    font-size:13px;
  }

  ul{
    list-style-type:none;
  }
    @media(max-width:500px){
        li{
            float:right !important;
        }
    }
   
`;

const Footer: React.FunctionComponent = ({ props: any,typeVariable,dialogShowVideo}) => {
    const { language, setLanguage } = useContext(LangContext);
  return (
    <StyledFooter className={typeVariable === "editor" ? "editorFooter" : ""}>
        <ul style={typeVariable === 'editor' ? { marginTop: '27px' } : {}}>
            <li style={{ float: 'left', marginTop:"-3px"}}
            >
                <span onClick={() => setLanguage( 'cs')} style={language ==="cs" ? {fontWeight:"bold"} : null}>CZ</span> <span> / </span> <span  style={language ==="en" ? {fontWeight:"bold"} : null} onClick={() => setLanguage( 'en')}>EN</span>

            </li>
            <li style={{ float: "left" }}><a href={"https://app.obalero.com/help"}>
                <FormattedMessage {...messages.main_menu_items_faq_help}></FormattedMessage></a></li>
            <li><a href={"https://www.obalero.com/contact"} target={"_blank"}>
                <FormattedMessage {...messages.footer_contact_us}></FormattedMessage> </a></li>
            <li><a href={"https://www.obalero.com/terms-of-use/"}
                   target={"_blank"}><FormattedMessage {...messages.footer_privacy_terms}></FormattedMessage> </a></li>
            <li><a href={"https://www.obalero.com/"} target={"_blank"}>Obalero Web</a></li>
        </ul>
    </StyledFooter>
  )
};
export default Footer;